import React, { useEffect, useState } from "react";
import AddACar from "shared/components/AddACar";
import { connect } from "react-redux";

type Props = {
  onGoingData: any;
  userData: any;
};

function WelcomePage(props: Props) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="layout--welcome">
      <div className="py-5">
        <div className="company-details">
          {/* <img
            className="logo"
            src={require("assets/images/hyundai-logo.svg")}
            alt=""
          /> */}
          <span className="name">{props.userData.corporate}</span>
        </div>
        <h2 style={{color: '#4f4f4f'}} className="title mt-5">
          You have {props.onGoingData.total} {props.onGoingData.total == 1?"car":"cars"} on sale right now.
        </h2>
        <div className="row m-0">
          <div className="col-md-8 mb-4 px-0">
            <span className="sub-title">
              {props.onGoingData.parkSell} {props.onGoingData.parkSell == 1?"car":"cars"} have been put for Park & Sale.
            </span>
          </div>
          <div className="col-md-4 my-3 px-0 text-center add-car-container">
            <AddACar />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    onGoingData: state.Subscriptions.onGoingData,
    userData: state.Login.userData
  };
};

export default connect(mapStateToProps)(WelcomePage);
