import React, { useEffect } from "react";
import loginImage from "../assets/images/img-login.svg";
import { LoginForm } from "../shared/components/LoginForm";
import TopNav from "shared/components/TopNav";

const Login = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="layout--login">
      {/* <img
        className="logo m-3"
        src='https://s3.ap-south-1.amazonaws.com/com.gigacars.documents/c99%20logo_1709200303040.png'
        alt={process.env.REACT_APP_SITE_NAME}
        height="120px"
        width="auto"
        style={{
          position: 'absolute',
          top: '0px'
        }}
      /> */}
      <TopNav />
      <div className="layout-content mx-auto">
        <div className="left-section  d-flex justify-content-center">
          <img className="ad-image" src={"https://s3.ap-south-1.amazonaws.com/com.circuits99.documents/loginpage_1715679224834.png"} alt="" />
        </div>
        <div className="login-container">
          <LoginForm />
        </div>
      </div>
      <span className="copyright-text">
        Copyright © {new Date().getFullYear()} {process.env.REACT_APP_SITE_NAME}
      </span>
    </div>
  );
};

export default Login;
