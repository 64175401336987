import React, { useState, useEffect } from "react";
import iconHam from "assets/icons/icon-ham.svg";
import iconFrame from "assets/icons/icon-frame.svg";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import * as loginActions from "redux/actions/login";
type Props = {
  logout: any; // redux action
  // userData: any; //redux;
  // saveLoginData: any; //action redux
  // updatedToken: any; //action redux
};

function TopNav(props: Props) {
  const [menuHidden, setMenuHidden] = useState(true);
  const history = useHistory();
  let { path, url } = useRouteMatch();

  function showMenu() {
    setMenuHidden(!menuHidden);
  }
  function hideMenu() {
    setMenuHidden(true);
  }

  function logout() {
    history.push("/login");
    props.logout();
  }

  return (
    <div className="layout--header--section px-3">
      <div className="inner-content">
        <NavLink
          to="/"
          className="d-flex justify-content-center align-items-center"
        >
          <img
            className="logo"
            src='https://s3.ap-south-1.amazonaws.com/com.gigacars.documents/c99%20logo_1709200303040.png'
            alt={process.env.REACT_APP_SITE_NAME}
            height="60px"
            width='auto'
          />
        </NavLink>
        {!path.includes('login') && <div className="header-content hide-mobile">
          <ul className="m-0 p-0">
            {/* <NavLink to={`${url}/buy`}>
              <li>BUY A USED CAR</li>
            </NavLink>{" "} */}
            {/*
            <NavLink to={`${url}/sell`}>
              <li>SELL</li>
            </NavLink> */}
            <NavLink to={`${url}/dashboard`}>
              <li>DASHBOARD</li>
            </NavLink>
            {/* <NavLink to={`${url}/faqs`}>
              <li>FAQs</li>
            </NavLink> */}
            <a
              onClick={() => {
                logout();
              }}
            >
              <li className="logout">
                <span>LOGOUT</span>
                <img
                  src={require("../../assets/icons/icon-logout.png")}
                  alt=""
                />
              </li>
            </a>
          </ul>
        </div>}
      </div>
      {!path.includes('login') && <div className="hide-desktop top-right-menu">
        <div
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center'
          }}
        >
          <img
            className="pointer"
            src={iconHam}
            alt="hamburger icon"
            id="menu"
            style={{
              height: '40px',
            }}
            onClick={() => showMenu()}
          />
        </div>
      </div>}
      <div
        className="overlay"
        onTouchEnd={() => {
          hideMenu();
        }}
        hidden={menuHidden}
      ></div>
      <div className="mobile-menu hide-desktop" hidden={menuHidden}>
        <div className="header">
          <h6 className="my-auto">Menu</h6>
          <img
            className="pointer close-icon"
            src={iconFrame}
            alt="close icon"
            id="exitMenu"
            onClick={() => hideMenu()}
          />
        </div>
        <div className="content">
          <ul className="p-0">
            {/* <NavLink to={`${url}/buy`} onClick={() => hideMenu()}>
              <li>BUY A USED CAR</li>
            </NavLink> */}
            {/*
            <NavLink to={`${url}/sell`} onClick={() => hideMenu()}>
              <li>SELL</li>
            </NavLink> */}
            <NavLink to={`${url}/dashboard`} onClick={() => hideMenu()}>
              <li>DASHBOARD</li>
            </NavLink>
            {/* <NavLink to={`${url}/faqs`} onClick={() => hideMenu()}>
              <li>FAQs</li>
            </NavLink> */}
            <a
              onClick={() => {
                logout();
              }}
            >
              <li className="logout">
                {" "}
                <span>LOGOUT</span>
                <img
                  src={require("../../assets/icons/icon-logout.png")}
                  alt=""
                />
              </li>
            </a>
          </ul>
          <div
            style={{
              marginTop: '-40px'
            }}
          >
            <img
              className="logo mx-0 p-0 my-4"
              src="https://s3.ap-south-1.amazonaws.com/com.gigacars.documents/c99%20logo_1709200303040.png"
              alt={process.env.REACT_APP_SITE_NAME}
              height="60px"
              width='auto'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, loginActions)(TopNav);
