import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/login";
import { useHistory } from "react-router-dom";
import { Button, Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { verifyOTP, loginUser, corporateLogin } from "shared/services/customer";
import { ActivityTracker } from "shared/services/activityTracker";

type Props = {
  phoneNumber: string;
  isOtpSent: boolean;
  authenticated: boolean;
  login: any; //redux action;
  saveLoginData: any; //redux action
  updatedToken: any; //redux action
  customerAuthId: string;
};

function OTPForm(props: Props) {
  const [OTPError, setOTPError] = useState(false);
  const [OTPErrorMessage, setOTPMessage] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [isSending, setSending] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  let otpOne: any = React.createRef();
  let otpTwo: any = React.createRef();
  let otpThree: any = React.createRef();
  let otpFour: any = React.createRef();
  let inputGroupOTP: any = React.createRef();
  const history = useHistory();
  const [customerAuthId, setCustomerAuthId] = useState("");

  useEffect(() => {
    otpOne.current.focus();
  }, [props.isOtpSent]);

  useEffect(() => {
    setCustomerAuthId(props.customerAuthId);
  }, [props.customerAuthId]);

  function handleSubmitOTP(event: any) {
    setSending(true);
    let otp = [
      otpOne.current.value,
      otpTwo.current.value,
      otpThree.current.value,
      otpFour.current.value,
    ].join("");
    var list: string[] = inputGroupOTP.current.classList.value.split(" ");
    let indexof = list.indexOf("invalid");
    if (otp.length == 4) {
      if (indexof != -1) {
        list = inputGroupOTP.current.classList.splice(1, indexof);
      }

      verifyOTP(props.phoneNumber.toString(), parseInt(otp), customerAuthId)
        .then((response) => {
          props.updatedToken(response.data.data.token);
          props.saveLoginData(response.data.data);
          sessionStorage.setItem("user", JSON.stringify(response.data.data));
          ActivityTracker("user_has_logged_in", response.data);
          setTimeout(() => {
            setSending(false);
            props.login();
            history.push("/dashboard");
          }, 1000);
        })
        .catch((error) => {
          setOTPError(true);
          setSending(false);
          console.log(error);
          //Todo: Figure out why parsing fails
          //let errorRequest = JSON.parse(error.request.response)
          setOTPMessage("Invalid OTP please try again");
        });
    } else {
      if (indexof == -1) {
        list.push("invalid");
      }
      setOTPError(true);
      setSending(false);
      setOTPMessage("Invalid OTP please try again");
    }
    inputGroupOTP.current.classList = list.join(" ");
    event.preventDefault();
  }
  function moveToNext(e: any) {
    let target = e.currentTarget;
    if (e.keyCode == 8 && target.previousSibling != null) {
      target.value = "";
      target.previousElementSibling.focus();
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (target.value.length == target.maxLength && target.id != "3") {
        target.value = e.key;
        target.nextElementSibling.focus();
      }
    }
  }
  function onNumberChange(id: string, value: string) {
    let savedNo = otpValue.split("");
    let newno;
    if (value != "" && savedNo != null) {
      savedNo.push(id);
      newno = savedNo.join("");
    } else if (value == "" && savedNo != null) {
      let indexOf = savedNo.indexOf(id);
      savedNo.splice(indexOf, 1);
      newno = savedNo.join("");
    } else {
      newno = value;
    }
    console.log(newno);
    setOtpValue(newno);
  }
  function resendOTP() {
    corporateLogin(props.phoneNumber).then((res) => {
      setOtpSent(true);
      setCustomerAuthId(res.data.customerOTPAuthorizationId);
      setTimeout(() => {
        setOtpSent(false);
      }, 3000);
    });
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={otpSent}
      >
        <Alert severity="info">OTP has been sent!</Alert>
      </Snackbar>
      <h6 className="description mt-3">
        Enter OTP sent to XXXXXXX{parseInt(props.phoneNumber) % 1000}
      </h6>
      <form className="form-otp" onSubmit={handleSubmitOTP}>
        <div className="input-group mt-4" ref={inputGroupOTP}>
          <input
            type="text"
            id="0"
            ref={otpOne}
            name="otpOne"
            className="input-otp"
            maxLength={1}
            pattern="[0-9]*"
            inputMode="numeric"
            autoFocus={true}
            onKeyUp={(element) => {
              moveToNext(element);
            }}
            onChange={(e) => {
              onNumberChange(e.currentTarget.id, e.currentTarget.value);
            }}
          />
          <input
            type="text"
            id="1"
            ref={otpTwo}
            name="otpTwo"
            className="input-otp"
            maxLength={1}
            pattern="[0-9]*"
            inputMode="numeric"
            onKeyUp={(element) => {
              moveToNext(element);
            }}
            onChange={(e) => {
              onNumberChange(e.currentTarget.id, e.currentTarget.value);
            }}
          />
          <input
            type="text"
            id="2"
            ref={otpThree}
            name="otpThree"
            className="input-otp"
            maxLength={1}
            pattern="[0-9]*"
            inputMode="numeric"
            onKeyUp={(element) => {
              moveToNext(element);
            }}
            onChange={(e) => {
              onNumberChange(e.currentTarget.id, e.currentTarget.value);
            }}
          />
          <input
            type="text"
            id="3"
            ref={otpFour}
            name="otpFour"
            className="input-otp"
            maxLength={1}
            pattern="[0-9]*"
            inputMode="numeric"
            onKeyUp={(element) => {
              moveToNext(element);
            }}
            onChange={(e) => {
              onNumberChange(e.currentTarget.id, e.currentTarget.value);
            }}
          />
        </div>
        <h6 className="error-message" hidden={!OTPError}>
          {OTPErrorMessage}
        </h6>
        <Link
          component="button"
          type="button"
          onClick={() => {
            resendOTP();
          }}
          className="m-0 w-100 text-left"
          style={{color: '#ED1C24'}}
        >
          Resend OTP
        </Link>
        <Button
          className="btn-primary-b my-3 submit-button"
          type="submit"
          size="large"
          disabled={otpValue.toString().length == 4 ? false : true}
        >
          Submit
          <svg
            className="loader-svg"
            version="1.1"
            id="loader-1"
            x="0px"
            y="0px"
            width="25px"
            height="25px"
            viewBox="0 0 50 50"
            enableBackground="new 0 0 50 50"
            display={isSending ? "inherit" : "none"}
          >
            <path
              fill="#fff"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </Button>
      </form>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    authenticated: state.Login.authenticated,
  };
};
export default connect(mapStateToProps, actions)(OTPForm);
