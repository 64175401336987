import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  IconButton,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {
  GetMake,
  GetModel,
  GetYear,
  GetVariant,
} from "shared/services/vehicle";
import {
  addTheCar,
  getURL,
  uploadDocument,
  GetColor,
} from "shared/services/vehicle";
import { connect } from "react-redux";
import * as subscriptionAction from "redux/actions/subscriptions";
import { Alert } from "@material-ui/lab";
import { ActivityTracker } from "shared/services/activityTracker";
import { compressFile } from "shared/utils/imageFormatting";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

type Props = {
  reloadList: any;
  userData: any;
};

function AddACar(props: Props) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const [selectedMake, setSelectedMake] = useState<any>();
  const [makeList, setMakeList] = useState([]);
  const [selectedModel, setSelectedModel] = useState<any>();
  const [modelList, setModelList] = useState<string[]>([]);
  const [registrationNo, setRegistrationNo] = useState("");
  const [kilometersCovered, setKilometersCovered] = useState(0);
  const [expectedPrice, setExpectedPrice] = useState(0);
  const [rcDoc, setRCDoc] = useState<any>({ name: "" });
  const [insuranceDoc, setInsuranceDoc] = useState<any>({ name: "" });
  const [nocDoc, setNocDoc] = useState<any>({ name: "" });
  const [erros, setErrors] = useState(null);
  const [colorList, setColorList] = useState([]);
  const [colorType, setColorType] = useState<any>();
  const [uploading, setUploading] = useState(0);
  const [allModels, setallModels] = useState([]);
  const [variantList, setVariantList] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>();
  const [carAdded, setCarAdded] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [errorList, setErrorList] = useState({
    registraionNo: {
      value: false,
      message: "",
    },
    kmsCovered: {
      value: false,
      message: "",
    },
    expectedPrice: {
      value: false,
      message: "",
    },
  });
  const [selectedDate, setSelectedDate] = React.useState<Date | null>();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setSelectedVariant(null);
    setVariantList([]);
    GetVariant(selectedModel, selectedMake._id, date).then((response) => {
      if (response.status) {
        setVariantList(response.data.data);
      } else {
        console.log("status is false");
      }
    });
  };

  useEffect(() => {
    GetMake().then((response) => {
      setMakeList(response.data.data);
    });

    GetColor().then((response) => {
      setColorList(response.data.items);
    });
  }, []);

  //Dialog
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setSelectedMake(null);
      setSelectedModel(null);
      setSelectedYear(null);
      setSelectedVariant(null);
      setSelectedYear(null);
      setColorType(null);
      setRCDoc({ name: "" });
      setNocDoc({ name: "" });
      setInsuranceDoc({ name: "" });
    }
  }, [open]);

  //on selection of Make Type
  const handleChangeMake = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedMake(event.target.value);
    setSelectedModel(null);
    setModelList([]);
    setSelectedVariant(null);
    setSelectedYear(null);
    setYearList([]);
    GetModel(event.target.value._id).then((response) => {
      let modelArr: string[] = [];
      let modelList: any[] = [];
      response.data.data.forEach((model: any) => {
        if (modelArr.indexOf(model) == -1) {
          modelArr.push(model);
          modelList.push(model);
        }
      });
      setallModels(response.data.data);
      setModelList(modelList);
    });
  };

  //on selection of model type
  const handleChangeModel = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedModel(event.target.value);
    setSelectedVariant(null);
    setSelectedYear(null);
    setYearList([]);
    GetYear(event.target.value, selectedMake._id).then((response) => {
      setYearList(response.data.data);
    });
  };

  const handleChangeVariant = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedVariant(event.target.value);
  };

  //on selection of color type
  const handleChangeColor = (event: React.ChangeEvent<{ value: any }>) => {
    setColorType(event.target.value);
  };

  //check validation
  function handleValidation() {
    if (
      selectedModel != null &&
      registrationNo != "" &&
      kilometersCovered != 0 &&
      colorType != null &&
      expectedPrice != 0 &&
      rcDoc.name != ""
      // insuranceDoc.name != "" &&
      // nocDoc.name != ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  //on submission of form
  const submitForm = (e: any) => {
    e.preventDefault();
    //if passed validation submit form
    if (handleValidation()) {
      let formData = {
        make: selectedMake._id,
        model: selectedVariant._id,
        color: colorType.type,
        userKms: kilometersCovered,
        registrationNumber: registrationNo,
        expectedPrice: expectedPrice,
        rcUrl: rcDoc.webURL,
        insuranceUrl: insuranceDoc.webURL,
        nocUrl: nocDoc.webURL,
        corporateFlag: true,
        mfgDate:selectedDate?.toISOString().replace('Z', '')
      };
      addTheCar(formData)
        .then((response) => {
          handleClose();
          props.reloadList(true);
          setCarAdded(true);
          setTimeout(() => {
            setCarAdded(false);
          }, 3000);
          let data = {
            response: response.data,
            registrationNo: registrationNo,
            userData: props.userData,
          };
          ActivityTracker("user_added_car", data);
        })
        .catch(() => {
          setErrorMessage("Opps, Something went wrong!");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
        });
    } else {
      setErrorAlert(true);
      setErrorMessage("All fields are mandatory!");
      setTimeout(() => {
        setErrorAlert(false);
      }, 3000);
    }
  };
  //on change of RC Doc
  useEffect(() => {
    if (rcDoc.name != "") {
      getURL(rcDoc.name)
        .then((response) => {
          compressFile(rcDoc, uploadRc);
          function uploadRc(compressedFile: any) {
            uploadDocument(response.data.url, compressedFile).then(() => {
              let doc = rcDoc;
              doc.webURL = response.data.url.url + "/" + rcDoc.name;
              setRCDoc(doc);
              setUploading(0);
            });
          }
        })
        .catch(() => {
          setErrorMessage("Opps, Something went wrong!");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
          setUploading(0);
        });
    }
  }, [rcDoc]);

  //on change of Insurance Doc
  useEffect(() => {
    if (insuranceDoc.name != "") {
      getURL(insuranceDoc.name)
        .then((response) => {
          compressFile(insuranceDoc, uploadInsu);
          function uploadInsu(compressedFile: any) {
            uploadDocument(response.data.url, compressedFile).then(() => {
              let doc = insuranceDoc;
              doc.webURL = response.data.url.url + "/" + insuranceDoc.name;
              setInsuranceDoc(doc);
              setUploading(0);
            });
          }
        })
        .catch(() => {
          setErrorMessage("Opps, Something went wrong!");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
          setUploading(0);
        });
    }
  }, [insuranceDoc]);

  //on change of noc Doc
  useEffect(() => {
    if (nocDoc.name != "") {
      getURL(nocDoc.name)
        .then((response) => {
          compressFile(nocDoc, uploadNOC);
          function uploadNOC(compressedFile: any) {
            uploadDocument(response.data.url, compressedFile).then(() => {
              let doc = nocDoc;
              doc.webURL = response.data.url.url + "/" + nocDoc.name;
              setNocDoc(doc);
              setUploading(0);
            });
          }
        })
        .catch(() => {
          setErrorMessage("Opps, Something went wrong!");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
          setUploading(0);
        });
    }
  }, [nocDoc]);

  const onFileChange = (event: any) => {
    let fileData = event.target.files[0];
    setUploading(1);
    switch (event.target.id) {
      case "RC": {
        setRCDoc(fileData);
        break;
      }
      case "insurance": {
        setInsuranceDoc(fileData);
        break;
      }
      case "NOC": {
        setNocDoc(fileData);
        break;
      }
    }
  };

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  return (
    <div className="layout--addcar">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={carAdded}
      >
        <Alert severity="info">Your car has been added successfully!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorAlert}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Button
        variant="contained"
        className="btn-primary-b add-car-btn"
        size="large"
        onClick={handleClickOpen("paper")}
      >
        Add a Car
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={false}
      >
        <DialogTitle id="scroll-dialog-title">Add a car</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <form className="layout--form">
              <div className="d-flex my-4">
                <span className="mr-5 title">Vehicle Make/Model</span>
                <div>
                  <FormControl
                    variant="filled"
                    size="small"
                    className="data-field mr-5 mb-2"
                    color="secondary"
                  >
                    <InputLabel id="make-select-label">Select Make</InputLabel>
                    <Select
                      labelId="make-select-label"
                      value={selectedMake}
                      onChange={handleChangeMake}
                    >
                      {makeList.map((make: any) => {
                        return (
                          <MenuItem value={make} key={make._id}>
                            {make.make}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    className="data-field mr-5 mb-2"
                    color="secondary"
                  >
                    <InputLabel id="model-select-label">
                      Select Model
                    </InputLabel>
                    <Select
                      labelId="model-select-label"
                      value={selectedModel}
                      onChange={handleChangeModel}
                    >
                      {modelList.map((modelItem: any) => {
                        return (
                          <MenuItem value={modelItem} key={modelItem}>
                            {modelItem}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    className="data-field mr-5 mb-2"
                    color="secondary"
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disabled={selectedModel && selectedMake ? false : true}
                        disableToolbar
                        variant="inline"
                        margin="normal"
                        id="date-picker-inline"
                        views={["year", "month"]}
                        label="Select mfg date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {/* <Select
                      labelId="model-select-label"
                      value={selectedYear}
                      onChange={selectedYearAction}
                    >
                      {yearList.map((year: number, index: number) => {
                        return (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    className="data-field mr-5 mb-2"
                    color="secondary"
                  >
                    <InputLabel id="model-select-label">
                      Select Variant
                    </InputLabel>
                    <Select
                      labelId="model-select-label"
                      value={selectedVariant}
                      onChange={handleChangeVariant}
                    >
                      {variantList.map((variant: any) => {
                        return (
                          <MenuItem value={variant} key={variant._id}>
                            {variant.variant}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="d-flex my-4">
                <span className="mr-5 title">Registration No.</span>
                <TextField
                  error={errorList.registraionNo.value}
                  helperText={errorList.registraionNo.message}
                  className="data-field"
                  color="secondary"

                  variant="filled"
                  size="small"
                  placeholder="e.g., KA01AB1234"
                  onChange={(event: any) => {
                    setRegistrationNo(event.target.value);
                    let errorValue = event.target.value.match(
                      /(([A-Za-z]){2,3}(|-)(?:[0-9]){1,2}(|-)(?:[A-Za-z]){2}(|-)([0-9]){1,4})|(([A-Za-z]){2,3}(|-)([0-9]){1,4})/g
                    )
                      ? false
                      : true;
                    let text = errorValue
                      ? "Enter valid registration number"
                      : "";
                    let errorListNew = errorList;
                    errorListNew.registraionNo = {
                      value: errorValue,
                      message: text,
                    };
                    setErrorList(errorListNew);
                  }}
                />
              </div>
              <div className="d-flex my-4">
                <span className="mr-5 title">Kilometers Covered</span>
                <TextField
                  error={errorList.kmsCovered.value}
                  helperText={errorList.kmsCovered.message}
                  size="small"
                  color="secondary"
                  className="data-field"
                  variant="filled"
                  type="number"
                  placeholder=" e.g. 9,000km"
                  onChange={(event: any) => {
                    setKilometersCovered(parseInt(event.target.value));
                    let errorValue =
                      parseInt(event.target.value) > 0 ? false : true;
                    let text = errorValue ? "Kms cannot be empty" : "";
                    let errorListNew = errorList;
                    errorListNew.kmsCovered = {
                      value: errorValue,
                      message: text,
                    };
                    setErrorList(errorListNew);
                  }}
                />
              </div>
              <div className="d-flex my-4">
                <span className="mr-5 title">Colour</span>
                <FormControl
                  variant="filled"
                  color="secondary"
                  size="small"
                  className="data-field mr-5"
                >
                  <InputLabel id="color-select-label">
                    Select a colour
                  </InputLabel>
                  <Select
                    labelId="color-select-label"
                    onChange={handleChangeColor}
                    value={colorType}
                  >
                    {colorList.map((color: any, index: number) => {
                      return (
                        <MenuItem value={color} key={index}>
                          {color.type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="d-flex my-4">
                <span className="mr-5 title">Expected Price</span>
                <TextField
                  error={errorList.expectedPrice.value}
                  helperText={errorList.expectedPrice.message}
                  className="data-field"
                  variant="filled"
                  color="secondary"
                  placeholder="e.g., ₹ 1,99,999"
                  size="small"
                  type="number"
                  onChange={(event: any) => {
                    setExpectedPrice(parseInt(event.target.value));
                    let errorValue =
                      parseInt(event.target.value) > 0 ? false : true;
                    let text = errorValue
                      ? "Expected Price cannot be empty"
                      : "";
                    let errorListNew = errorList;
                    errorListNew.expectedPrice = {
                      value: errorValue,
                      message: text,
                    };
                    setErrorList(errorListNew);
                  }}
                />
              </div>
              <div className="document-upload-container">
                <h6> Please upload listed documents</h6>
                <h6> Upload format should be in .pdf, .jpg, .jpeg or .png</h6>
                <ul className="d-flex">
                  <li
                    className="mr-2 rc-doc-container"
                    title="click to upload RC Document in .pdf, .jpg or .png"
                    hidden={rcDoc.name == "" && uploading == 0 ? false : true}
                  >
                    <input
                      className="w-100 h-100"
                      type="file"
                      accept=".png, .jpeg, .pdf, .jpg"
                      id="RC"
                      onChange={onFileChange}
                      onClick={onInputClick}
                    />
                    <img src={require("assets/icons/icon-upload.svg")} alt="" />
                    <h6>RC Doc</h6>
                    <h6 className="mandatory">* Mandatory</h6>
                  </li>
                  <li
                    hidden={rcDoc.name != "" && uploading == 0 ? false : true}
                    className="active"
                  >
                    <img src={require("assets/icons/icon-doc.svg")} alt="" />
                    <h6 className="text-truncate">{rcDoc.name}</h6>
                    <IconButton
                      className="delete"
                      onClick={() => {
                        setRCDoc({ name: "" });
                      }}
                    ></IconButton>
                  </li>
                  <li
                    title="click to upload Insurance Document in .pdf, .jpg or .png"
                    hidden={
                      insuranceDoc.name == "" && uploading == 0 ? false : true
                    }
                    className="mr-2"
                  >
                    <input
                      className="w-100 h-100"
                      type="file"
                      accept=".png, .jpeg, .pdf"
                      id="insurance"
                      onChange={onFileChange}
                      onClick={onInputClick}
                    />
                    <img src={require("assets/icons/icon-upload.svg")} alt="" />
                    <h6>Insurance Document</h6>
                  </li>
                  <li
                    hidden={
                      insuranceDoc.name != "" && uploading == 0 ? false : true
                    }
                    className="active"
                  >
                    <img src={require("assets/icons/icon-doc.svg")} alt="" />
                    <h6 className="text-truncate">{insuranceDoc.name}</h6>
                    <IconButton
                      className="delete"
                      onClick={() => {
                        setInsuranceDoc({ name: "" });
                      }}
                    ></IconButton>
                  </li>
                  <li
                    title="click to upload NOC Document in .pdf, .jpg or .png"
                    hidden={nocDoc.name == "" && uploading == 0 ? false : true}
                  >
                    <input
                      className="w-100 h-100"
                      type="file"
                      accept=".png, .jpeg, .pdf"
                      id="NOC"
                      onChange={onFileChange}
                      onClick={onInputClick}
                    />
                    <img src={require("assets/icons/icon-upload.svg")} alt="" />
                    <h6>NOC</h6>
                  </li>
                  <li
                    hidden={nocDoc.name != "" && uploading == 0 ? false : true}
                    className="active"
                  >
                    <img src={require("assets/icons/icon-doc.svg")} alt="" />
                    <h6 className="text-truncate">{nocDoc.name}</h6>
                    <IconButton
                      className="delete"
                      onClick={() => {
                        setNocDoc({ name: "" });
                      }}
                    ></IconButton>
                  </li>
                  <li
                    className={`bg-transparent justify-content-center align-items-center ${
                      uploading == 1 ? " d-flex" : ""
                    }`}
                    hidden={uploading == 1 ? false : true}
                  >
                    <CircularProgress />
                  </li>
                </ul>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="justify-content-start">
          <Button size="large" className="btn-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="large" className="btn-primary-b" onClick={submitForm}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userData: state.Login.userData,
  };
};

export default connect(mapStateToProps, subscriptionAction)(AddACar);
